import React from 'react';
import DownloadLink from '../elements/DownloadLink';

/* Agenden_Veranstaltungen  */
import Beispiel_Agenda_Round_Table_Lipidmanagement from '../../assets/downloads/Agenden_Veranstaltungen/Beispiel_Agenda_Round-Table_Lipidmanagement.pdf';
import Beispiel_Agenda_Treffen_Lipidmanagement_in_der_Region from '../../assets/downloads/Agenden_Veranstaltungen/Beispiel_Agenda_Treffen_Lipidmanagement_in_der_Region.pdf';
import Beispiel_Aktionsplan_zur_Erstellung_eines_regionalen_Behandlungspfads from '../../assets/downloads/Agenden_Veranstaltungen/Beispiel_Aktionsplan_zur_Erstellung_eines_regionalen_Behandlungspfads.pdf';
import Beispiel_Agenda_Netzwerk_Treffen from '../../assets/downloads/Agenden_Veranstaltungen/Beispiel-Agenda_Netzwerk-Treffen.pdf';
import Diabetestag_Charite from '../../assets/downloads/Agenden_Veranstaltungen/Diabetestag_Charite_neu.pdf';
/* Einladungsschreiben */
import Beispiel_Einladung_Netzwerk_Treffen_1 from '../../assets/downloads/Einladungsschreiben/Beispiel_Einladung_Netzwerk_Treffen_1.pdf';
import Beispiel_Einladung_Netzwerk_Treffen_2 from '../../assets/downloads/Einladungsschreiben/Beispiel_Einladung_Netzwerk_Treffen_2.pdf';
import Beispiel_Einladung_Netzwerk_Veranstaltung from '../../assets/downloads/Einladungsschreiben/Beispiel_Einladung_Netzwerk-Veranstaltung.pdf';
/* Informationsflyer_zum_Lipidmanagement */
import Informationsflyer_fuer_Patienten_Cholesterin from '../../assets/downloads/Informationsflyer_zum_Lipidmanagement/Informationsflyer_fuer_Patienten_Cholesterin.pdf';
/* Patienten_Dokumentationsboegen */
import Familienanamnesebogen from '../../assets/downloads/Patienten_Dokumentationsboegen/Familienanamnesebogen.pdf';

/* Beispiele Patienten und Therapiepfade */

import Praxisleitfaden_Niederbayern_Oberpfalz_Final from '../../assets/downloads/Praxisleitfaden_Niederbayern_Oberpfalz_Final.pdf';

/* Vorschlaege_fuer_Teilnehmer */
import Netzwerk_Treffen from '../../assets/downloads/Vorschlaege_fuer_Teilnehmer/Netzwerk-Treffen.pdf';
const Downloads = () => {
    return (
        <div className='page'>
            <div className='headline1 pb2'>Downloads und weitere Informationen</div>
            <div className="headline2"> Auf der „Good Networking Practice“ Plattform haben wir einige Dokumente und Informationsmaterial hinterlegt, die Sie bei der Planung und Durchführung der Maßnahmen in der Netzwerk-Arbeit unterstützen können.</div>

            <div className='text-container-grey-round'>

                <div className="linearSection-text">
                    <div className="headline2">Agenden Veranstaltungen</div>
                    <ul>
                        <DownloadLink label="Beispiel Agenda Round-Table Lipidmanagement" pdfPath={Beispiel_Agenda_Round_Table_Lipidmanagement} />
                        <DownloadLink label='Beispiel Agenda Treffen Lipidmanagement in der Region' pdfPath={Beispiel_Agenda_Treffen_Lipidmanagement_in_der_Region} />
                        <DownloadLink label='Beispiel Aktionsplan zur Erstellung eines regionalen Behandlungspfads' pdfPath={Beispiel_Aktionsplan_zur_Erstellung_eines_regionalen_Behandlungspfads} />
                        <DownloadLink label='Beispiel-Agenda Netzwerk Treffen' pdfPath={Beispiel_Agenda_Netzwerk_Treffen} />
                        <DownloadLink label='Beispiel-Agenda Patientenveranstaltung Diabetestag' pdfPath={Diabetestag_Charite} />
                        
                    </ul>
                </div>

                <div className="linearSection-text">
                    <div className="headline2">Einladungsschreiben</div>
                    <ul>
                        <DownloadLink label="Beispiel Einladung Netzwerk Veranstaltung" pdfPath={Beispiel_Einladung_Netzwerk_Veranstaltung} />
                        <DownloadLink label="Beispiel Einladung Netzwerk Treffen 1" pdfPath={Beispiel_Einladung_Netzwerk_Treffen_1} />
                        <DownloadLink label="Beispiel Einladung Netzwerk Treffen 2" pdfPath={Beispiel_Einladung_Netzwerk_Treffen_2} />
                    </ul>
                </div>

                <div className="linearSection-text">
                    <div className="headline2">Informationsflyer zum Lipidmanagement</div>
                    <ul>
                        <DownloadLink label="Poster Familiäre Hypercholesterinämie" pdfPath='https://lp.thieme.de/emag/CP/Poster_Repatha_2020/#0' />
                        <DownloadLink label="Informationsflyer für Patienten Cholesterin" pdfPath={Informationsflyer_fuer_Patienten_Cholesterin} />
                    </ul>
                </div>

                <div className="linearSection-text">
                    <div className="headline2">Patientendokumentationsbogen</div>
                    <ul>
                        <DownloadLink label="Familienanamnesebogen" pdfPath={Familienanamnesebogen} />
                    </ul>
                </div>

                <div className="linearSection-text">
                    <div className="headline2">Vorschläge für Teilnehmer-Übersicht</div>
                    <ul>
                        <DownloadLink label="Vorschläge für Teilnehmer an Netzwerk-Treffen" pdfPath={Netzwerk_Treffen} />
                    </ul>
                </div>

                <div className="linearSection-text">
                    <div className="headline2">Beispiele Patienten- und Therapiepfade</div>
                    <ul>
                    <DownloadLink label='Beispiel Aktionsplan zur Erstellung eines regionalen Behandlungspfads' pdfPath={Beispiel_Aktionsplan_zur_Erstellung_eines_regionalen_Behandlungspfads} />
                    <DownloadLink label='Praxisleitfaden Niederbayern' pdfPath={Praxisleitfaden_Niederbayern_Oberpfalz_Final} />
                    
                    </ul>
                </div>

                <div className="linearSection-text">
                    <div className="headline2">Interessante Links</div>
                    <ul>
                        <div className="headline2">Fachkreise Seite</div>
                        
                        <p>Präsentationsmaterial zum Lipidmanagement, der Versorgungsrealität, Slidedeck für
                        Patientenschulungen, Kongressberichte, kardiovaskuläres Risiko und vieles mehr auf
                        <a href='https://fachkreise.amgen.de/' target='_blank' rel="noreferrer" className='downloads-text'> fachkreise.amgen.de</a>. 
                        <br></br>Gerne kann Ihnen hier auch Ihr Kontakt in der Medizinabteilung
                        von AMGEN weiteres, umfangreiches Präsentationsmaterial zur Verfügung stellen.</p>

                        <div className="headline2">Weitere Links zum Lipidmanagement</div>
                        <a href='https://www.cholesterin-neu-verstehen.de/' target='_blank' rel="noreferrer" className='downloads-text'>https://www.cholesterin-neu-verstehen.de/</a><br></br>
                        <a href='https://www.lipid-liga.de' target='_blank' rel="noreferrer" className='downloads-text'> www.lipid-liga.de</a>
                       <br></br>
                        Übersicht zertifizierte Lipidzentren in Deutschland:<br></br>
                        <a href='https://www.lipid-liga.de/lipidologen-finden/' target='_blank' rel="noreferrer" className='downloads-text'>https://www.lipid-liga.de/lipidologen-finden/</a>
                    </ul>



                </div>

            </div>
        </div>
    );
}

export default Downloads;
