import React from 'react';
import DownloadLink from '../elements/DownloadLink';
import aktionIcon from '../../assets/Aktion.svg';
import ActionsLink from '../elements/ActionsLink';
import HeadlineAusgangspunkt from '../elements/HeadlineAusgangspunkt';
import Beispiel_Aktionsplan_zur_Erstellung_eines_regionalen_Behandlungspfads from '../../assets/downloads/Agenden_Veranstaltungen/Beispiel_Aktionsplan_zur_Erstellung_eines_regionalen_Behandlungspfads.pdf';

const Aktion3 = () => {
    return (
        <div className='page'>
            <div className=''>
                <HeadlineAusgangspunkt/>
                <div className='headline3'>Schritt 5</div>
                {/* TITLE */}
                <div className='headline1 pb2'>Detaillierte Ausarbeitung der Aktion</div>

                {/* Text */}
                {/* TITLE */}
                <div className='inline'>
                    <div className="headline1 pb2">
                        <div className="actionCard-icon-container-onpage">
                            <img src={aktionIcon} alt="icon" className='aktionCard-icon' />
                        </div>
                    </div>
                    <div className='headline1 pb2 headline-aktion'>Etablierung eines Patientenpfads</div>

                </div>
                {/* TODO: Responsive design for mobile */}
                <p>Um die Versorgung von kardiovaskulären Hochrisiko-Patient:innen innerhalb eines Netzwerks in der Region zu optimieren bzw. zu gewährleisten sind gemeinsam beschlossene Patientenpfade ein Schritt in die richtige Richtung. Folgende Fragestellungen sollten innerhalb des Netzwerks geklärt werden:</p>

                <div className="box">
                    <ul>
                        <li>Wie soll der Patientenpfad aussehen?</li>
                        <li>Haben sich alle Teilnehmer:innen im Netzwerk auf einen Patientenpfad verständigt?</li>
                        <li>Sind die einzelnen beteiligten Zuweiser:innen (Fach- und Hausärzt:innen) berücksichtigt?</li>
                        <li>Sind die Wege der Patient:innen zu den einzelnen Fach- und Hausärzt:innen eindeutig im Netzwerk?</li>
                        <li>Welche regionalen oder KV-spezifischen Besonderheiten müssen beachtet werden?</li>
                        <DownloadLink label='Beispiel Aktionsplan zur Erstellung eines regionalen Behandlungspfads' pdfPath={Beispiel_Aktionsplan_zur_Erstellung_eines_regionalen_Behandlungspfads} />
                    </ul>
                </div>
                <div className="linearSection-legende"><b>KV: </b> Kassenärztliche Vereinigung </div>
                <div className="explain-text center"><ActionsLink path={'/aktionen'} breadcrumbName="Aktionen" /></div>
                
            </div>
        </div>
    );
}

export default Aktion3;
