import React from 'react';
import { useState } from 'react';
import ColorCard from '../elements/ColorCard';
import Netzwerkplaner from '../elements/Netzwerkplaner';
import DirektLink from '../elements/DirektLink';

//import { GlobalVarContext } from '../Main';

const Netzwerkplanung = () => {

    const [directAreaVisibility, setDirectAreaVisibility] = useState(false);
    //const ausgangspunktNetzwerkplaner = useContext(GlobalVarContext);
    function handleDirektArea(){
       setDirectAreaVisibility(true);
    }
    return (
        <div className='page'>
            <div className=''>
                <div className='kapitel-text'>Netzwerkplanung</div>
                {/* <div className='headline3'>Schritt 2</div> */}
                <div className='headline1 pb2'>Planen Sie Ihr Netzwerk</div>

                <Netzwerkplaner />

                <DirektLink onMyClick={handleDirektArea} label='Ich möchte die Fragen überspringen und direkt mit der Neuanlage oder Optimierung des Netzwerks beginnen' />
               
                <div className={directAreaVisibility ? 'answers-container' : 'answers-container hidden'}>
                     {/* to do: beforeRedirectFunction in ColorCard - 'neu' übergeben*/}
                    <ColorCard label="Neues Netzwerk gründen" color="bg-burgundy colorCard-mid" path='/ausgangspunkt' breadcrumbName='Netzwerk anlegen' resetBreadcrumbs={true}/>
                     {/* to do: beforeRedirectFunction in ColorCard - 'opt' übergeben*/}
                    <ColorCard label="Vorhandenes Netzwerk optimieren" color="bg-burgundy colorCard-mid" path='/ausgangspunkt_opt' breadcrumbName='Netzwerk optimieren' highlightIfActive='true' resetBreadcrumbs={true}/>
                   {/*  <ColorCard label="TEST" color="bg-burgundy colorCard-mid" before_RedirectFunction={console.log("->NEU")} path='/ausgangspunkt'/> */}
                    {/* <WorkplaceCard  label={'TEST 2'} path='/ausgangspunkt' color="bg-burgundy colorCard-mid" beforeRedirectFunction={() => ausgangspunktNetzwerkplaner.setAusgangspunkt('NEUNEU')}/> */}
                </div>

            </div>
        </div>
    );
}
export default Netzwerkplanung;